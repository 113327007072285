<template>
    <div class="row justify-content-center background-white">
        <div class="column">
            <div class="card border-0">
                <div class="card-body px-lg-5 py-lg-5">
                    <h1 class="text-center mb-3">
                        Reset Your Password
                    </h1>
                    <p class="text-center sub-title mb-3">Forgot your password? Enter your email below and we will send a password reset link to your email.</p>
                    <form role="form" @submit.prevent="handleResetPassword">
                        <base-input
                            required
                            placeholder="Email"
                            v-model="fields.email"
                            :error="errors.email"
                            :valid="!errors.email ? true : false"
                            @input="validate('email')"
                            addon-left-icon="ni ni-email-83"
                        />
                        <base-button block type="black" nativeType="submit" class="my-3 bg-dark">Reset password</base-button>
                    </form>
                    <div class="row">
                        <div class="col-12 account-signup">
                            <span>Already have an account?&nbsp;</span>
                            <router-link to="/login" class="text-bold">Log in</router-link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    import tools from '@/utils/tools'
    import { validateSchema, validateInput } from '@/container/forgot-password/model'

    export default {
        name: 'forgot-password',
        data: () => ({
          fields: {
            email: '',
          },
          errors: {
            email: '',
          },
        }),
        methods: {
            validate(field) {
                this.errors = validateInput(field, this.fields, this.errors)
            },
            async handleResetPassword() {
                // let request;
                const { isValid, errors } = validateSchema(this.fields)
                if (!isValid)
                  return this.errors = errors

                // convert email to lowercase
                this.fields.email = this.fields.email.toLowerCase() 
                const request = await this.$store
                    .dispatch('AUTH/requestForgotPassword', this.fields)

                if (request) {
                  this.$notify({
                    timeout: 10000,
                    icon: 'ni ni-check-bold',
                    type: 'success',
                    title: 'Password Reset Sent',
                    message: `If an account matching your username is found, you should receive the password reset email shortly.`
                  })

                  this.$router.push({name: 'login'})
                } 
                // else {
                //   this.$notify({
                //     icon: 'fa fa-times',
                //     type: 'danger',
                //     title: 'Error',
                //     message: `Something went wrong!`
                //   })
                // }
            },
            async handleLogin() {
                const user = await this.$store.dispatch('AUTH/authenticate', this.model)
                user && this.$router.push({ name: 'request'})
                user && this.$notify({
                    icon: 'ni ni-check-bold',
                    type: 'success',
                    title: 'Success',
                    message: `Welcome back ${tools.capitalize(user.first_name)}!`
                })
            }
        }
    }
</script>
<style scoped>
    .background-white {
        width: 100%;
        height: 100vh;
        background: #fff;
        display: flex;
        align-items: center;
    }

    .account-signup {
        color: #000;
        font-style: normal;
        font-size: 16px;
        line-height: 22px;
        font-weight: normal;
    }

    .account-signup a {
        color: black !important;
        font-weight: 500;
    }

    .form {
        > * {
            margin-bottom: 24px !important;
        }

        input {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
        }
    }

    .bg-dark {
        background: #333333 !important;
        color: #fff;
    }

    .sub-title {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.65);
    }

    .column {
        width: 500px;
    }

    .row {
        margin: 0 auto;
    }
</style>

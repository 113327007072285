<template>
    <div class="row justify-content-center background-white">
        <div class="column">
            <div class="card border-0">
                <img src="/img/brand/vidsprint.png" :style="{'margin': '0 auto', 'width': '50%'}" class="mb-6">
                <div class="px-lg-5">
                    <h1 class="text-center mb-3">Log in</h1>
                    <form role="form" @submit.prevent="handleLogin">
                        <base-input
                            class=""
                            placeholder="Email"
                            addon-left-icon="ni ni-email-83"
                            v-model="model.email"
                        />
                        <base-input
                            class=""
                            placeholder="Password"
                            type="password"
                            addon-left-icon="ni ni-lock-circle-open"
                            v-model="model.password"
                        />
                        <base-button block type="black" nativeType="submit" class="my-4 bg-dark">Sign in</base-button>
                    </form>
                    <div class="row">
                        <div class="col-12 account-signup">
                            <span>Forgot your password?&nbsp;</span>
                            <router-link to="/forgot-password" class="text-bold">Reset password</router-link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
	import tools from '@/utils/tools'
  export default {
    name: 'login',
    data() {
      return {
        model: {
          email: '',
          password: ''
        }
      }
    },
		methods: {
			async handleLogin() {
                // convert email to lowercase
                this.model.email = this.model.email.toLowerCase() 

				const user = await this.$store.dispatch('AUTH/authenticate', this.model)
				user && this.$router.push({ name: 'request'})
				user && this.$notify({
					icon: 'ni ni-hands-hi',
					type: 'success',
					title: 'Success',
					message: `Welcome back ${tools.capitalize(user.first_name)}!`
				})
			}
		}
  }
</script>
<style scoped>
    .background-white {
        width: 100%;
        height: 100vh;
        background: #fff;
        display: flex;
        align-items: center;
    }

    .account-signup {
        color: #000;
        font-style: normal;
        font-size: 16px;
        line-height: 22px;
        font-weight: normal;
    }

    .account-signup a {
        color: black !important;
        font-weight: 500;
    }

    .form {
        > * {
            margin-bottom: 24px !important;
        }

        input {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
        }
    }

    .bg-dark {
        background: #333333 !important;
        color: #fff;
    }

    .column {
        width: 500px;
    }

    .row {
        margin: 0 auto;
    }
</style>

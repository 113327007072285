import * as yup from 'yup'
import upperFirst from 'lodash/upperFirst'

export const resetPasswordSchema = yup.object().shape({
  email: yup.string().email().required(),
})

export const validateSchema = data => {
	try {
    resetPasswordSchema.validateSync(data, { abortEarly: false, allowUnknown: true })
    return {
      errors: {},
      isValid: true,
    }
  } catch (error) {
    const errors = error.inner.reduce((acc, el) => {
      const { path, message } = el
      acc[path] = message.replace(path, upperFirst(path.replace(/_/g, ' ')))
      return acc
    }, {})
    return { isValid: false, errors }
  }
}

export const validateInput = (key, fields, errors) => {
	const { errors: vErrors } = validateSchema(fields)
	return { ...errors, [key]: vErrors[key] }
}
<template>
    <div class="row justify-content-center background-white">
        <div class="column">
            <div class="card border-0">
                <div class="card-body px-lg-5 py-lg-5">
                    <h1 class="text-center mb-3">
                        Reset Your Password
                    </h1>
                    <p class="text-center sub-title mb-3">We can’t wait to serve you at Videoprawn! Before we show you around, let’s create your new password.</p>
                    <form role="form" @submit.prevent="handleCreateAccount">
                        <base-input
                            required
                            placeholder="Password"
                            v-model="fields.password"
                            :error="errors.password"
                            :valid="!errors.password ? true : false"
                            @input="validate('password')"
                            addon-left-icon="ni ni-lock-circle-open"
                            type="password"

                        />
                        <base-input
                            required
                            placeholder="Confirm password"
                            v-model="fields.confirm_password"
                            :error="errors.confirm_password"
                            :valid="!errors.confirm_password ? true : false"
                            @input="validate('confirm_password')"
                            addon-left-icon="ni ni-lock-circle-open"
                            type="password"

                        />
                        <div class="row">
                            <div class="col-md-12 remember">
                                Remember, your password must:
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 requirement">
                                <i class="fa fa-check" :class="{success: fields.password.match(/^[A-Za-z0-9]/)}"/>
                                <span>Starts with Alphanumeric character</span>
                            </div>
                            <div class="col-md-12 requirement">
                                <i class="fa fa-check" :class="{success: fields.password.length >= 8}"/>
                                <span>Have at least 8 characters</span>
                            </div>
                            <div class="col-md-12 requirement">
                                <i class="fa fa-check" :class="{success: fields.password.match(/[A-Z]/)}"/>
                                <span>One Uppercase Letter</span>
                            </div>
                            <div class="col-md-12 requirement">
                                <i class="fa fa-check" :class="{success: fields.password.match(/[a-z]/)}"/>
                                <span>One Lowercase Letter</span>
                            </div>
                            <div class="col-md-12 requirement">
                                <i class="fa fa-check" :class="{success: oneSpecial}"/>
                                <span>One Special Character</span>
                            </div>
                            <div class="col-md-12 requirement">
                                <i class="fa fa-check" :class="{success: fields.password.match(/[0-9]/)}"/>
                                <span>One Digit</span>
                            </div>
                        </div>
                        <base-button block type="black" nativeType="submit" class="my-3 bg-dark">Reset password</base-button>
                    </form>
                    <div class="row" v-if="!isAuthenticated">
                        <div class="col-12 account-signup">
                            <span>Already have an account?&nbsp;</span>
                            <router-link to="/login" class="text-bold">Log in</router-link>
                        </div>
                    </div>
                    <div class="row" v-if="isAuthenticated">
                        <div class="col-12 account-signup">
                            <span>Don't want to update?&nbsp;</span>
                            <router-link to="/request" class="text-bold">Take me back</router-link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    import qs from "qs"
    import { validateSchema, validateInput } from '@/container/reset-password/model'

    export default {
        name: 'reset-password',
        data: () => ({
          fields: {
            password: '',
            confirm_password: '',
          },
          errors: {
            password: '',
            confirm_password: '',
          },
        }),
        methods: {
            validate(field) {
                this.errors = validateInput(field, this.fields, this.errors)
            },
            async handleCreateAccount() {
                const { isValid, errors } = validateSchema(this.fields)
                if (!isValid)
                  return this.errors = errors

                const request = await this.$store
                    .dispatch('AUTH/requestResetPassword', {
                        ...this.fields,
                        token: this.token
                    })

                if (request) {
                  this.$notify({
                    icon: 'ni ni-check-bold',
                    type: 'success',
                    title: 'Success',
                    message: `Password updated successfully`
                  })

                  this.$router.push({name: this.redirectTo})
                } else {
                  this.$notify({
                    icon: 'fa fa-times',
                    type: 'danger',
                    title: 'Error',
                    message: `Something went wrong!`
                  })
                }
            },

            async handleValidateToken() {
                const valid = await this.$store.dispatch("AUTH/requestValidateToken", qs.stringify({
                    token: this.token,
                    type: 'reset-password'
                }))

                !valid && this.$router.push({name: this.redirectTo})
            }
        },
        computed: {
            oneSpecial() {
                return this.fields?.password?.match(/[\s!"#$'()*+,-.:;<=>?@[\]^_`{|}~]/)
            },

            token() {
                return this.$route?.query?.token
            },

            isAuthenticated() {
                return Boolean(this.$store.state.AUTH.user )
            },

            redirectTo() {
                return this.isAuthenticated ? 'request' : 'login'
            }
        },

        beforeCreate() {
            if (!this.$route?.query?.token) {
                this.$notify({
                    icon: 'fa fa-times',
                    type: 'danger',
                    title: 'Error',
                    message: `Token is missing`
                })

               return this.$router.push({name: this.redirectTo})
            }
        },

        created() {
            if (!this.token)
                return this.$router.push({name: this.redirectTo})

            this.handleValidateToken()
        },
    }
</script>
<style scoped>
    .background-white {
        width: 100%;
        height: 100vh;
        background: #fff;
        display: flex;
        align-items: center;
    }

    .account-signup {
        color: #000;
        font-style: normal;
        font-size: 16px;
        line-height: 22px;
        font-weight: normal;
    }

    .account-signup a {
        color: black !important;
        font-weight: 500;
    }

    .form {
        > * {
            margin-bottom: 24px !important;
        }

        input {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
        }
    }

    .bg-dark {
        background: #333333 !important;
        color: #fff;
    }

    .sub-title {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.65);
    }

    .column {
        width: 500px;
    }

    .row {
        margin: 0 auto;
    }

    .fa {
        color: #fff;
        padding: 3px;
        border-radius: 100%;
        font-size: 12px;
        background: #9E9E9E;
        margin-right: 10px;
    }
    .fa.success {
        background: #4CAF50;
    }

    .requirement {
        padding: 0;
        margin: 2px 0;
        font-size: 13px;
    }

    .remember {
        padding: 0;
        margin: 0 0 5px;
    }
</style>

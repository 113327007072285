<template>
  <div :class="`page-${$route.name}`">
    <base-header type="gradient-custom" class="pb-6 pb-8 pt-5 pt-md-8" />

    <loader :loading="loading" class="mt-4"/>
    <div class="container-fluid mt--8" v-if="!loading">
      <div class="row justify-content-end mb-2" v-if="isAdmin">
        <div class="col col-xl-3">
          <base-button
              block
              class=""
              icon="fa fa-plus"
              type="success"
              @click="handleToggleDialog"> 
              Create Knowledge Base
          </base-button>
        </div>
      </div>
      <div class="kb-container row mt-5">
        <div class="col mb-4" v-for="data of records" :key="data.id">
          <div class="card p-4">
            <iframe
              :src="embededURL(data.link)"
              height="350"
              frameborder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen>
            </iframe>
            <div class="mt-4">
              <small><b>{{ data.title }}</b></small> 
            </div>
            <div class="mt-4 text-right" v-if="isAdmin">
              <base-button type="primary" class="btn-radius" :size="'sm'" @click="handleEdit(data)">Update</base-button>
              <base-button type="danger" class="btn-radius" :size="'sm'" @click="handleDelete(data)">Delete</base-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal :show="knowledgeBaseDialog" @close="handleToggleDialog" type="mini">
      <h3 slot="header" class="modal-title" id="modal-title-default">
        {{ fields.id ? 'Update' : 'Create' }} Knowledge Base
      </h3>
      <div>
        <base-input
            required
            autoresize
            textarea
            label="Title"
            v-model="fields.title.value"
            :error="fields.title.error"
            :valid="!fields.title.error ? true : false"
            @input="validate(fields.title)"
            @blur="validate(fields.title)"
        />
        <base-input
            autoresize
            textarea
            required
            label="Link"
            v-model="fields.link.value"
            :error="fields.link.error"
            :valid="!fields.link.error ? true : false"
            @input="validate(fields.link)"
            @blur="validate(fields.link)"
        >
        </base-input>
      </div>
      <template slot="footer">
        <base-button type="link" class="ml-auto btn-radius" @click="handleToggleDialog">Close</base-button>
        <base-button type="purple" class="btn-radius" @click.prevent="handleAction">Save</base-button>
      </template>
    </modal>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import qs from 'qs';

export default {
  name: 'knowledge-base-container',
  components: {},
  data: () => ({
    knowledgeBaseDialog: false,
    fields: {
      id: '',
      title: {
        value: '',
        error: '',
        required: true
      },
      link: {
        value: '',
        error: '',
        required: true
      }
    }
  }),
  created() {
    this.handleListChange();
  },
  methods: {
    handleToggleDialog() {
      this.knowledgeBaseDialog = !this.knowledgeBaseDialog;
      if (!this.knowledgeBaseDialog) {
        this.fields.id = this.fields.title.value = this.fields.link.value = ''
      }
    },
    validate(field) {
        field.error = ''
        if (field.required && !field.value) {
            field.error = 'This field is required'
        }
    },
    async handleAction() {
      let valid = true
      this.validate(this.fields.link)
      if (this.fields.link.error) {
          valid = false
      }

      this.validate(this.fields.title)
      if (this.fields.title.error) {
          valid = false
      }

      if (!valid) {
        return
      }

      const request = {
        title: this.fields.title.value,
        link: this.fields.link.value
      }

      let response = null 
      if (!this.fields.id) {
        response = await this.$store.dispatch('KNOWLEDGE_BASE/createKnowledgeBase', request)
      }  else {
        request.id = this.fields.id
        response = await this.$store.dispatch('KNOWLEDGE_BASE/updateKnowledgeBase', request)
      }

      if (!response) {
        this.$notify({
          icon: 'fa fa-times',
          type: 'danger',
          title: 'Error',
          message: `Something went wrong!`
        });

        return
      } 
      
      this.handleListChange();
      this.$notify({
        icon: 'ni ni-check-bold',
        type: 'success',
        title: 'Success',
        message: `Knowledge Base successfully ${request.id ? 'updated' : 'created'}!`
      });

      this.handleToggleDialog()
      this.fields.id = this.fields.title.value = this.fields.link.value = ''
    },

    handleEdit(data) {
      const { id, title, link } = data
      this.fields.id = id
      this.fields.title.value = title
      this.fields.link.value = link
      this.handleToggleDialog()
    },

    async handleDelete(data) {
      if (!confirm('Are you sure you want to delete this knowledge base?')) {
        return
      }

      data.status = 'Deleted'
      const response = await this.$store.dispatch('KNOWLEDGE_BASE/deleteKnowledgeBase', data)

      if (!response) {
        this.$notify({
          icon: 'fa fa-times',
          type: 'danger',
          title: 'Error',
          message: `Something went wrong!`
        });

        return
      } 
      
      this.$notify({
        icon: 'ni ni-check-bold',
        type: 'success',
        title: 'Success',
        message: `Knowledge Base successfully deleted!`
      });

      this.handleListChange();
    },
    handleListChange() {
      this.$store.dispatch('KNOWLEDGE_BASE/listKnowledgeBase',  qs.stringify({ status: 'Active'}));
    },
    embededURL(url) {  
      // source: Vimeo
      if (url.includes('vimeo.com')) {
        if (!url.includes('iframe src')) {
          return `${url}&sidedock=0`
        }

        const srcStr = url.split(/<iframe src="(.*?)"/)
        let src = srcStr.find(v => v.includes('https')) || null
        if (src) {
          src = `${src}&sidedock=0`
        }
        return src || url
      }

      // source: YouTube
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*)(?:(\?t|&start)=(\d+))?.*/
      const match = url.match(regExp);  
      if (match && match[2].length == 11) {    
        const startTime = match[4] ? `?start=${match[4]}&controls=0&rel=0` : '?controls=0&rel=0'
        return `https://www.youtube.com/embed/${match[2]}${startTime}`;  
      } 

      // other sources
      return url
    }
  },
  computed: {
    ...mapState('KNOWLEDGE_BASE', {
      records: state => state.data,
      loading: state => state.loading,
    }),
    ...mapGetters("AUTH", [
        'isAdmin'
    ]),
  }
};
</script>
<style scoped>
  .kb-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 100%;
    /* min-height: 80vh; */
  }

  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
</style>

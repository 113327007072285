<template>
     <div :class="`page-${$route.name}`">
    <base-header type="gradient-custom" class="pb-6 pb-8 pt-5 pt-md-8" />
  
      <div class="container-fluid mt--8">
    
        <div class="row">
          <div class="col">
            <div class="card shadow">
              <div class="card-header bg-transparent no-border">
                <div class="row align-items-center">
                  <div class="col">
                    <h3 class="mb-0">Email and App notifications</h3>
                  </div>
                  <div class="col text-right">
                    <!-- <base-button type="primary" size="sm">See all</base-button> -->
                  </div>
                </div>
              </div>
              <div class = "card-body mx-4">
                  <div class="row">
                      <div class="col-6">
                          Preferred email
                      </div>
                      <div class="col-6 text-right" style="cursor:pointer; color: #0469d5;">
                          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                          About notifications
                      </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                       <select class="form-select mt-3 light p-2 form-select-lg mb-3" aria-label=".form-select-lg example">
                            <option selected>Open this select menu</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div>
                  </div>
                  <div class="row my-4">
                      <div class="col-12">
                          Send me notifications for:
                      </div>
                  </div>
                  <!-- notification for -->
                  <div class="row" v-for="(items,index) in notifSettingsFor" :key="index" >
                      <div class="col-12">
                          <div class="form-check" v-if="items.id == 'activity_updates' ">
                                <input class="form-check-input" type="checkbox" :checked="items.value" :id="items.id"
                                @change="updateAppSettings(items.id)"
                                :disabled="isDisabledActivity "
                                >
                                <label class="form-check-label font-weight-bold" :for="items.id">
                                    {{items.title}}
                                </label>
                          </div>
                          <div class="form-check" v-if="items.id != 'activity_updates' ">
                                <input class="form-check-input" type="checkbox" :checked="items.value" :id="items.id"
                                @change="updateAppSettings(items.id)"
                                
                                >
                                <label class="form-check-label font-weight-bold" :for="items.id">
                                    {{items.title}}
                                </label>
                          </div>
                          <div>
                              <p style="margin-left: 1.3rem;">  {{items.body}}</p>
                          </div>
                      </div>
                  </div>
                   <div class="row my-4">
                      <div class="col-12">
                          Send me notifications to:
                      </div>
                  </div>
                  
                  <!-- notification to -->
                    <div class="row" v-for="(items,index) in notifSettingsTo" :key="index">
                      <div class="col-12">
                          <div class="form-check">
                                <input class="form-check-input" type="checkbox" :checked="items.value" :id="items.id"
                                @change="updateAppSettings(items.id)"
                                >
                                <label class="form-check-label font-weight-bold" :for="items.id">
                                    {{items.title}}
                                </label>
                          </div>
                          <div>
                              <p style="margin-left: 1.3rem;">  {{items.body}}</p>
                          </div>
                      </div>
                  </div>

              </div>
       
              <div class="card-footer d-flex justify-content-between bg-transparent">
                  <div class="row w-100">
                      <div class="col-6">
                          <div class="d-flex w-100 align-items-center clickableElement">
                              <i class="fa fa-plus mr-1" aria-hidden="true"></i>
                              Add New Email
                          </div>
                      </div>
                      <div class="col-6 text-right clickableElement" >
                          Remove Email
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
   
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default{
    data() {
        return {
            notifSettingsTo: [],
            notifSettingsFor: [],
            disabledActivity:(this.settings?.app_settings?.mentions_only) ? 1 : 0
        }
    },
    methods: {
        loadAppSettings(){
            if(this.settings?.app_settings) {
                this.notifSettingsTo = [
                    {
                        id: 'in_app_notification',
                        title: 'In App Notifications',
                        body: 'Check this if you want to receive in app notifications',
                        value: this.settings?.app_settings?.in_app_notification
                    },
                    {
                        id: 'email_notification',
                        title:'Email Notifications',
                        body:'Check this if you want to receive email notifications',
                        value: this.settings?.app_settings?.email_notification
                    }
                ]

                this.notifSettingsFor = [
                    {
                        id: 'activity_updates',
                        title:'Activity updates',
                        body:`New projects, @mentions, latest versions links and completion notifications for projects you're a collaborator on`,
                        value: this.settings?.app_settings?.activity_updates,
                        disabled: (this.settings?.app_settings?.mentions_only == true) ? 1 : 0
                        
                    },
                    {
                        id: 'mentions_only',
                        title:'Mentions only',
                        body:'New projects where you are added as a collaborator and @mentions',
                        value: this.settings?.app_settings?.mentions_only
                    },
                    {
                        id: 'daily_summaries',
                        title:'Daily summaries',
                        body:'New projects where you are a collaborator and upcoming project completion',
                        value: this.settings?.app_settings?.daily_summaries
                    }
                ]
                this.disabledActivity = (this.settings?.app_settings?.mentions_only == true) ? true : false
            }
        },
        async updateAppSettings(id) {
            let payload = {}
            console.log("notification");
            console.log(id);
            this.notifSettingsTo.forEach(element => {
                if(element.id === id){
                    element.value = !element.value
                }
                payload[`${element.id}`] = element.value
            });

            this.notifSettingsFor.forEach(element => {
                if(element.id === id){
                    element.value = !element.value
                }
                 payload[`${element.id}`] = element.value
            });

            console.log('payload',payload.mentions_only)
            if(payload.mentions_only){
                this.disabledActivity = true;
                
            }else{
                this.disabledActivity = false;
            }
            
            const response = await this.$store.dispatch('AUTH/updateAppSettings', {
                user_id: this.user.id,
                preferred_email: this.user.email, 
                activity_updates: payload.activity_updates,
                mention_only: payload.mentions_only,
                daily_summaries: payload.daily_summaries,
                in_app_notif: payload.in_app_notification,
                email_notif: payload.email_notification,
                emails: []
            });

            if (response) {
                this.$notify({
                    icon: 'ni ni-check-bold',
                    type: 'success',
                    title: 'Settings update!',
                    message: `Successfully updated`
              })
            }
        }
    },
    created() {
        this.loadAppSettings();
    },
    computed: {
    ...mapState('AUTH', {
      user: state => state.user,
      settings: state => state.app_settings,
      isDisabledActivity() {
          
          return this.disabledActivity
      }
    }),
  },
}
</script>

<style scoped>

.clickableElement{
    cursor:pointer
}

</style>
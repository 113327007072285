<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
			<h1>This page is forbidden</h1>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'forbidden',
  }
</script>
<style>
</style>

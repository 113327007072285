<template>
    <div class="row justify-content-center background-white">
        <div class="column">
            <div class="card border-0">
                <div class="card-body px-lg-5 py-lg-5">
                    <h1 class="text-center mb-3">
                        Let’s get started
                    </h1>
                    <h1 class="text-center mb-3 d-none">
                        Let’s get started with your 14 day trial
                    </h1>
                    <p class="text-center sub-title mb-3">You’re just a couple steps away from experiencing all the magic</p>
                    <form role="form" @submit.prevent="handleCreateAccount">
                        <base-input
                            required
                            placeholder="Firstname"
                            v-model="fields.first_name"
                            :error="errors.first_name"
                            :valid="!errors.first_name ? true : false"
                            @input="validate('first_name')"
                            addon-left-icon="ni ni ni-single-02"
                          />
                        <base-input
                            required
                            placeholder="Lastname"
                            v-model="fields.last_name"
                            :error="errors.last_name"
                            :valid="!errors.last_name ? true : false"
                            @input="validate('last_name')"
                            addon-left-icon="ni ni ni-single-02"
                        />
                        <base-input
                            required
                            placeholder="Password"
                            v-model="fields.password"
                            :error="errors.password"
                            :valid="!errors.password ? true : false"
                            @input="validate('password')"
                            addon-left-icon="ni ni-lock-circle-open"
                            type="password"
                        />
                        <base-input
                            required
                            placeholder="Confirm password"
                            v-model="fields.confirm_password"
                            :error="errors.confirm_password"
                            :valid="!errors.confirm_password ? true : false"
                            @input="validate('confirm_password')"
                            addon-left-icon="ni ni-lock-circle-open"
                            type="password"
                        />

                        <div class="row">
                            <div class="col-md-12 remember">
                                Remember, your password must:
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 requirement">
                                <i class="fa fa-check" :class="{success: fields.password.match(/^[A-Za-z0-9]/)}"/>
                                <span>Starts with Alphanumeric character</span>
                            </div>
                            <div class="col-md-12 requirement">
                                <i class="fa fa-check" :class="{success: fields.password.length >= 8}"/>
                                <span>Have at least 8 characters</span>
                            </div>
                            <div class="col-md-12 requirement">
                                <i class="fa fa-check" :class="{success: fields.password.match(/[A-Z]/)}"/>
                                <span>One Uppercase Letter</span>
                            </div>
                            <div class="col-md-12 requirement">
                                <i class="fa fa-check" :class="{success: fields.password.match(/[a-z]/)}"/>
                                <span>One Lowercase Letter</span>
                            </div>
                            <div class="col-md-12 requirement">
                                <i class="fa fa-check" :class="{success: oneSpecial}"/>
                                <span>One Special Character</span>
                            </div>
                            <div class="col-md-12 requirement">
                                <i class="fa fa-check" :class="{success: fields.password.match(/[0-9]/)}"/>
                                <span>One Digit</span>
                            </div>
                        </div>
                        <base-button block type="black" nativeType="submit" class="my-3 bg-dark">Sign up</base-button>
                    </form>
                    <div class="row">
                        <div class="col-12 account-signup">
                            <span>Already have an account?&nbsp;</span>
                            <router-link to="/login" class="text-bold">Log in</router-link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    import qs from 'qs'
    import tools from '@/utils/tools'
    import { mapState } from "vuex"
    import { validateSchema, validateInput } from '@/container/register/model'

    export default {
        name: 'register',
        data: () => ({
          fields: {
            first_name: '',
            last_name: '',
            password: '',
            confirm_password: '',
          },
          errors: {
            first_name: '',
            last_name: '',
            password: '',
            confirm_password: '',
          },
        }),
        methods: {
            invalidToken(message = 'Missing token') {
                this.$notify({
                    message,
                    icon: "fa fa-exclamation-triangle",
                    type: 'warning',
                    title: 'Token',
                })

                this.$router.push({ name: 'login'})
            },
            validate(field) {
                this.errors = validateInput(field, this.fields, this.errors)
            },
            async handleCreateAccount() {
                // let request;
                const { isValid, errors } = validateSchema(this.fields)
                if (!isValid)
                  return this.errors = errors

                const request = await this.$store
                    .dispatch('USER/createUser', {
                        ...this.fields,
                        token: this.token
                    })

                if (request) {
                    this.$notify({
                        icon: 'ni ni-check-bold',
                        type: 'success',
                        title: 'Success',
                        message: `Account setup successfully`
                    })
                    this.$router.push({ name: 'login'})
                }


                // if (!this.fields.id)
                //   request = await this.$store
                //     .dispatch('USER/createUser', this.fields)
                // else
                //   request = await this.$store
                //     .dispatch('QUESTION/updateQuestion', this.fields)

                // if (request) {
                //   this.handleTableChange()
                //   this.handleClearQuestionForm()
                //   this.$notify({
                //     icon: 'ni ni-check-bold',
                //     type: 'success',
                //     title: 'Success',
                //     message: `Question successfully ${this.fields.id ? 'updated' : 'created'}!`
                //   })
                // } else {
                //   this.$notify({
                //     icon: 'fa fa-times',
                //     type: 'danger',
                //     title: 'Error',
                //     message: `Something went wrong!`
                //   })
                // }
            },
            async handleLogin() {
                const user = await this.$store.dispatch('AUTH/authenticate', this.model)
                user && this.$router.push({ name: 'request'})
                user && this.$notify({
                    icon: 'ni ni-check-bold',
                    type: 'success',
                    title: 'Success',
                    message: `Welcome back ${tools.capitalize(user.first_name)}!`
                })
            },

            async validateToken() {
                const params = qs.stringify({
                    token: this.token,
                    type: 'user-invite',
                })

                await this.$store.dispatch('AUTH/requestValidateToken', params)
            }
        },

        computed: {
            ...mapState('AUTH', {
                isTokenValid: state => state.isTokenValid,
                loading: state => state.loginLoading
            }),
            ...mapState('USER', {
                userError: state => state.error,
            }),
            token() {
                return this.$route?.query?.token || ''
            },

            oneSpecial() {
                return this.fields?.password?.match(/[\s!"#$'()*+,-.:;<=>?@[\]^_`{|}~]/)
            },
        },

        watch: {
            isTokenValid(isValid) {
                if (isValid === null)
                    return

                if (!this.loading && !isValid)
                    this.invalidToken('The token is invalid')
            },

            userError(data) {
                console.log('error: ', data)
            }
        },

        created() {
            if (this.token)
                return this.validateToken()

            this.invalidToken()
        }
    }
</script>
<style scoped>
    .background-white {
        width: 100%;
        height: 100vh;
        background: #fff;
        display: flex;
        align-items: center;
    }

    .account-signup {
        color: #000;
        font-style: normal;
        font-size: 16px;
        line-height: 22px;
        font-weight: normal;
    }

    .account-signup a {
        color: black !important;
        font-weight: 500;
    }

    .form {
        > * {
            margin-bottom: 24px !important;
        }

        input {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
        }
    }

    .bg-dark {
        background: #333333 !important;
        color: #fff;
    }

    .sub-title {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.65);
    }

    .column {
        width: 500px;
    }

    .row {
        margin: 0 auto;
    }

    .fa {
        color: #fff;
        padding: 3px;
        border-radius: 100%;
        font-size: 12px;
        background: #9E9E9E;
        margin-right: 10px;
    }
    .fa.success {
        background: #4CAF50;
    }

    .requirement {
        padding: 0;
        margin: 2px 0;
        font-size: 13px;
    }

    .remember {
        padding: 0;
        margin: 0 0 5px;
    }
</style>

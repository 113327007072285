import * as yup from 'yup'
import upperFirst from 'lodash/upperFirst'

export const resetPasswordSchema = yup.object().shape({
  password: yup
      .string()
      .min(8, 'Must have at least 8 characters')
      .matches(/^[A-Za-z0-9]/, 'Must start with alphanumeric character')
      .matches(/[a-z]/, 'Must have at least one lowercase char')
      .matches(/[A-Z]/, 'Must have at least one uppercase char')
      .matches(/[0-9]/, 'Must have at least one digit')
      .matches(/[\s!"#$'()*+,-.:;<=>?@[\]^_`{|}~]/, 'Must have at least 1 number or special char (@,!,#, etc).')
      .required(),
  confirm_password: yup
      .string()
      .required()
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
})

export const validateSchema = data => {
	try {
    resetPasswordSchema.validateSync(data, { abortEarly: false, allowUnknown: true })
    return {
      errors: {},
      isValid: true,
    }
  } catch (error) {
    const errors = error.inner.reduce((acc, el) => {
      const { path, message } = el
      acc[path] = message.replace(path, upperFirst(path.replace(/_/g, ' ')))
      return acc
    }, {})
    return { isValid: false, errors }
  }
}

export const validateInput = (key, fields, errors) => {
	const { errors: vErrors } = validateSchema(fields)
	return { ...errors, [key]: vErrors[key] }
}
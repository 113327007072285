<template>
  <div :class="`page-${$route.name}`">
    <base-header type="gradient-custom" class="pb-6 pb-8 pt-5 pt-md-8" />

    <div class="container-fluid mt--8">
      <div class="row">
        <!-- request title -->
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="request-head">
            <h2>
              <span v-if="isNew">Submit a Project</span>
              <span v-if="!isNew">{{ data && data.name }}</span>
            </h2>
            <div class="request-notif-container">
              <span class="request-notif-label">Notifications for this Project</span>
              <base-switch v-model="hasNotification" />
            </div>
          </div>
        </div>
        <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12">
          <!-- Status Section -->
          <request-status-section-v3 v-if="data.deliverables && data.deliverables.length"></request-status-section-v3>
          <request-status-section v-else />
          
          <request-review-link v-if="requestReviewLink.length > 0 && !(data.deliverables && data.deliverables.length)" />  
          <!-- Request Section -->
          <request-section :isNew="isNew" />   
          <!-- Deliverables  -->
          <request-deliverable-container v-if="data.deliverables && data.deliverables.length"/>
          <!-- Message Section -->
          <request-message-section-v3  />
          <!-- Comment Section -->
          <request-comment-section />
           <!-- Logs Section -->
           
           <!--<request-comment-section v-if="!isNew && !isJustSubmitted" :isNew="!isNew" />-->
          <!--<request-message-section v-if="!isNew && !isJustSubmitted && !isUserAndClientIsNotActive" />-->
       
        </div>
        <div class="col-lg-3 col-md-12 col-sm-12 col-xs-12">
          <!-- Collaborators Section -->
          <request-collaborator-section-v3 v-if="data.deliverables && data.deliverables.length"/>
          <request-collaborator-section v-else/>
           <!-- Deliverables Section -->
          <request-deliverable-section :pending="true" v-if="!isNew"/>
          <request-logs-section v-if="!isNew"/>
        </div>
        <request-upload />
      </div>
      <div class="row col-md-12">
        <div class="pl-2 mt-5" v-if="isAdmin && requestId != 'new'">
          <button class="btn btn-sm btn-danger" @click="handleDeleteBrief"  >Delete this project</button>
        </div>
      </div>
     
    </div>
  </div>
</template>
<script>
const NEW = 'new';
import {
  RequestCollaboratorSection,
  RequestCommentSection,
  RequestDeliverableSection,
  RequestMessageSectionV3,
  RequestSection,
  RequestStatusSection,
  RequestStatusSectionV3,
  RequestUpload,
  RequestReviewLink,
  RequestLogsSection,
  RequestDeliverableContainer,
  RequestCollaboratorSectionV3
} from '@/container/request-details/component';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'request-details',
  components: {
    RequestUpload,
    RequestSection,
    RequestStatusSection,
    RequestStatusSectionV3,
    RequestCommentSection,
    RequestMessageSectionV3,
    RequestDeliverableSection,
    RequestCollaboratorSection,
    RequestReviewLink,
    RequestLogsSection,
    RequestDeliverableContainer,
    RequestCollaboratorSectionV3
  },
  data: () => ({
    notify: null
  }),
  methods: {
    async handleGetDetails() {
      if (this.requestId == 'new')
        return;

      await this.$store
          .dispatch('REQUEST/getRequestData', this.requestId)
          .catch(() => this.$router.push({ name: 'request' }));
      document.title = `${this.data.name} | VidSprint`;
    },
    async handleNotify(value) {
      this.$store.dispatch('REQUEST/updateNotify', {
        id: this.requestId,
        user_id: this.userId,
        action: value ? 'add' : 'remove'
      });
    },
    
    handleResetState() {
      this.$store.commit('REQUEST/resetState');
    },
    async handleDeleteBrief() {
        if(confirm(`Are you sure to delete ${this.data.name}?`)) {
          await this.$store
            .dispatch('REQUEST/deleteRequest', this.requestId)
            .then(async () => {
              this.$notify({
                timeout: 3000,
                icon: 'ni ni-check-bold',
                type: 'success',
                title: 'Deleted!',
                message: 'Successfully deleted a project'
              })

              // delete project feeds
              const feeds = await this.$store.dispatch('REQUEST/getRequestFeeds', { id: this.requestId })
              if (feeds && feeds.length) {
                const feedIDs = feeds.map(v => v.id)
                await this.$store.dispatch("REQUEST/deleteBulkRequestFeeds", {
                  id: 'bulk',
                  ids: feedIDs
                })
                await this.$root.$refs.feedsComponent.pullFeeds()
              }
              
              this.$router.push({ name: 'request' });
            })
            .catch(() => this.$router.push({ name: 'request' }));
        }
    }
  },
  created() {
    if (!(this.isUser || this.isAdmin) && this.requestId === 'new')
      return this.$router.push({ name: 'request' });

    if (this.isUserAndClientIsNotActive && this.requestId === 'new')
      return this.$router.push({ name: 'request' });

    this.handleResetState();
    this.handleGetDetails();
  },
  computed: {
    ...mapState('REQUEST', {
      data: state => state.request,
    }),
    ...mapGetters('AUTH', [
      'isAdmin',
      'isUser',
      'userId',
      'isUserAndClientIsNotActive'
    ]),
    isNew() {
      return String(this.requestId).toLowerCase() === NEW;
    },
    isJustSubmitted() {
      return this.data?.request_status === 'Submitted';
    },
    requestId() {
      return this.$route.params.id;
    },
    requestStatus() {
      return this.data?.request_status || '';
    },
    isNotificationOn() {

      return (this.isNew) ? true : this.data?.notify_users?.includes(this.userId);
    },
    requestReviewLink() {
      return this.data?.review_link || ''
    },
    hasNotification: {
      set(value) {
        if (typeof value !== 'boolean')
          return;

        this.notify = value;
        this.handleNotify(value);
      },

      get() {
        
          return (this.isNotificationOn && this.notify === null )
            ? this.isNotificationOn
            : (this.notify !== null)
                ? this.notify
                : false;
        
        
      }
    }
  },
  watch: {
    requestId(latest) {
      if (latest !== NEW)
        this.handleGetDetails();
    },
    requestStatus(){}
  }
};
</script>

<style scoped>
.border {
  border: 1px solid black !important;
}

.border-dash {
  border: 1px dashed #8C8C8C;
  box-sizing: border-box;
  border-radius: 10px;
}
</style>
